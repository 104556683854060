import type {WebsiteErrorEvent_1_1} from '@shopify/monorail/lib/schemas';

import {onceListener} from '../utils/listeners';
import type {Track, Store} from '../types';
import {Events} from '../types';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

const parseStrToInt = (strNum?: string) =>
  // eslint-disable-next-line no-negated-condition
  strNum !== undefined ? parseInt(strNum, 10) : undefined;

export const parseStack = (stack?: string) => {
  if (typeof stack !== 'string') {
    return {};
  }

  const sanitizedStack = stack?.replace(/[^\w\s\n():/.$-]/g, '');
  const matchFilename =
    sanitizedStack?.match(/(https?:\/\/[^\s:\n]+|\/[^\s:\n]+)/) || [];
  const matchLineAndCol = sanitizedStack?.match(/:\d+:\d+/);
  const lineAndColParts = matchLineAndCol?.[0].split(':');
  return {
    filename: matchFilename[1],
    lineNumber: parseStrToInt(lineAndColParts?.[1]),
    columnNumber: parseStrToInt(lineAndColParts?.[2]),
  };
};

export const initErrorTracking = (track: Track, store: Store) => {
  onceListener(
    Events.Error,
    (errorEvent: ErrorEvent) => {
      const rawStack =
        errorEvent?.error?.stack ||
        errorEvent?.error?.message ||
        errorEvent?.message;
      const {filename, lineNumber, columnNumber} = parseStack(rawStack) || {};

      // errorEvent
      const extraMetadata: {url: string; columnNumber?: number} = {
        url: location.href,
      };

      if (columnNumber) {
        extraMetadata.columnNumber = errorEvent.colno || columnNumber;
      }

      const event: MonorailEventSchema<WebsiteErrorEvent_1_1> = {
        schemaId: MonorailEventSchemaId.Error,
        payload: {
          pageViewToken: store.pageViewToken || '',
          message:
            errorEvent.error?.message || errorEvent.message || 'Unknown error',
          fileName: errorEvent.filename || filename,
          stack: errorEvent.error?.stack,
          lineNumber: errorEvent.lineno || lineNumber,
          extraMetadata: JSON.stringify(extraMetadata),
        },
      };

      track.dux(event);
    },
    window,
  );
};
