/* eslint-disable @typescript-eslint/naming-convention */
import {v4} from 'uuid';

import {RequestMethod} from '../enums';
import type {DuxEvent, EventMessage} from '../types';
import {isMonorailEvent} from '../types';
import {camelCaseToSnakeCaseAllProps} from '../utils/camelCaseToSnakeCase';
import type {MonorailEventSchema} from '../schema-types';

import type {
  DuxTrackerOptions,
  GtmClickEventData,
  GtmContext,
  GtmEvent,
  GtmScrollEventData,
  Store,
} from './types';
import debounce from './utils/debounce';

const EVENT_ENDPOINT = '/__dux';
const MAX_QUEUE_SIZE = 5;

export class DuxTracker {
  private debounceFlush: ReturnType<typeof debounce>;

  constructor(
    private store: Partial<Store>,
    private queue = new Array<DuxEvent<any>>(),
  ) {
    this.track = this.track.bind(this);
    this.flush = this.flush.bind(this);
    this.debounceFlush = debounce(this.flush.bind(this), 250);
  }

  track<T>(event: DuxEvent<T>, options?: DuxTrackerOptions) {
    const {
      flush = false,
      preserveCase = [],
      clientMessageId = v4(),
    } = options || {};

    if (isMonorailEvent(event)) {
      const transformEvent = {
        ...event,
        payload: camelCaseToSnakeCaseAllProps(event.payload, preserveCase),
        options: {
          convertEventCase: false,
        },
      } as MonorailEventSchema<T>;

      this.queue.push({
        metadata: {
          eventCreatedAtMs: Date.now(),
          clientMessageId,
        },
        ...transformEvent,
      });
    } else {
      this.queue.push(event);
    }

    if (flush || this.queue.length >= MAX_QUEUE_SIZE) {
      this.flush();
    } else {
      this.debounceFlush();
    }
  }

  flush() {
    const events = [...this.queue];
    this.queue.length = 0;

    if (events.length) {
      this.send(events);
    }
  }

  private async send<T>(events: DuxEvent<T>[]) {
    const message: EventMessage<T> = {
      multiTrackToken: this.store.multiTrackToken || '',
      sessionToken: this.store.sessionToken || '',
      events,
    };
    const body: string = JSON.stringify(message);
    const eventEndpoint = this.store.eventHandlerEndpoint || EVENT_ENDPOINT;

    try {
      if (this.isSendBeaconAndBlobSupported()) {
        const blobData = new window.Blob([body], {
          type: 'application/json',
        });
        const send = window.navigator.sendBeacon.bind(window.navigator);
        if (send(eventEndpoint, blobData)) {
          return Promise.resolve({
            status: 200,
          });
        }
      }
    } catch {
      // do nothing and fallback to fetch below
    }

    return fetch(eventEndpoint, {
      method: RequestMethod.Post,
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body,
    })
      .then((response) => {
        return {
          response,
          message,
          status: response.status || 0,
        };
      })
      .catch((err) => ({
        message,
        status: 0,
        error: err,
      }));
  }

  private isSendBeaconAndBlobSupported() {
    return (
      window &&
      window.navigator &&
      typeof window.navigator.sendBeacon === 'function' &&
      typeof window.Blob === 'function'
    );
  }
}

export class GtmTracker {
  private queueInterval: ReturnType<typeof setInterval> | null = null;

  constructor(
    private store: Store,
    private queue = new Array<GtmEvent>(),
  ) {
    this.track = this.track.bind(this);
  }

  track(
    data: GtmClickEventData | GtmScrollEventData,
    context: GtmContext = {},
  ) {
    const event = {
      project: 'brochure',
      service: this.store.service,
      user_token: this.store.multiTrackToken || '',
      event: 'event',
      event_non_interaction: 'false',
      event_context: context,
      experiment_variation_id: this.store.experimentVariationId || '',
      ...camelCaseToSnakeCaseAllProps(data),
    };

    if (window.dataLayer) {
      window.dataLayer.push(event);
    } else {
      this.queue.push(event);

      if (!this.queueInterval) {
        this.queueInterval = setInterval(() => {
          if (!window.dataLayer) {
            return;
          }

          if (this.queueInterval) {
            clearInterval(this.queueInterval);
          }

          while (this.queue.length) {
            const evt = this.queue.shift();
            window.dataLayer.push(evt);
          }
        }, 100);
      }
    }
  }
}
