import type {WebsiteClientPageView_2_13} from '@shopify/monorail/lib/schemas';

import {HOME_HANDLE} from '../constants';
import type {Store, Track, UpdatePageView} from '../types';
import type {MonorailEventSchema} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';

const DEFAULT_APPLICATION_NAME = 'default-application';

export const getCanonicalUrl = (href: string) => {
  const url =
    document.querySelector('link[rel="canonical"]')?.getAttribute('href') ||
    href;
  return url.split('?')[0].split('#')[0];
};

export const parseUrlHandle = (href: string | null | undefined) => {
  if (href) {
    try {
      const {pathname} = new URL(href);
      return (
        pathname.split('/').filter(Boolean).join('/') || HOME_HANDLE
      ).toLowerCase();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('malformed href', err);
    }
  }
  return '';
};

export const getAltPageHandle = (href: string, pathPrefix?: string) => {
  // try link alternate
  let handle = parseUrlHandle(
    document
      .querySelector('link[rel="alternate"][hreflang="en"]')
      ?.getAttribute('href'),
  );

  // try link canonical or current path
  if (!handle) {
    handle = parseUrlHandle(getCanonicalUrl(href));
  }

  // default to 'home' for all home pages
  if (pathPrefix && handle === pathPrefix.toLowerCase()) {
    handle = HOME_HANDLE;
  }

  return handle;
};

let cachedUrlStr: string;

export const handlePageView = ({
  track,
  store,
  softNavigation = false,
}: {
  track: Track;
  store: Store;
  softNavigation?: boolean;
}) => {
  const urlStr = location.toString();
  cachedUrlStr = urlStr;
  const url = new URL(urlStr);
  const domain = url.hostname;
  const path = url.pathname;
  const handle =
    store.metadata?.handle ||
    getAltPageHandle(url.href, store?.metadata?.site?.pathPrefix);
  const referrer = document.referrer || '';
  const experimentVariationId =
    store.metadata?.page?.experimentVariationId || store.experimentVariationId;
  const pageViewToken = store.pageViewToken || '';
  const siteEnvironment = store.mode || 'development';
  const event: MonorailEventSchema<WebsiteClientPageView_2_13> = {
    schemaId: MonorailEventSchemaId.PageView,
    payload: {
      pageViewToken,
      userLocale: (navigator && navigator.language) || '',
      url: urlStr,
      domain,
      path,
      urlHash: (location && location.hash) || '',
      canvasHeight: Math.round(document.body?.clientHeight) || 0,
      canvasWidth: Math.round(document.body?.clientWidth) || 0,
      viewportHeight: Math.round(window.innerHeight) || 0,
      viewportWidth: Math.round(window.innerWidth) || 0,
      // tsup & rollup replace this with the hardcoded version
      // eslint-disable-next-line no-process-env
      version: process.env.VERSION || '',
      siteEnvironment,
      application: store.service || DEFAULT_APPLICATION_NAME,
      handle,
      shopId: store.shopId,
      softNavigation,
      canonicalUrl: getCanonicalUrl(url.href),
      ...(store.tabToken ? {tabToken: store.tabToken} : {}),
    },
  };

  track.dux(event);

  if (store.metadata) {
    track.dux(
      {
        userLanguage: (navigator && navigator.language) || '',
        url: urlStr,
        referrer,
        httpStatusCode: handle === 'error-404' ? '404' : '200',
        pageViewToken: store.pageViewToken,
        siteEnvironment,

        // Only send optional attributes if they exist; don't leak schema when not needed
        ...(experimentVariationId ? {experimentVariationId} : {}),
        ...(store.sessionId ? {sessionId: store.sessionId} : {}),
        ...(store.identityUuid ? {identityUuid: store.identityUuid} : {}),
        ...(store.userId ? {userId: store.userId} : {}),
        ...(store.shopifyEmployee
          ? {shopifyEmployee: store.shopifyEmployee}
          : {}),
        ...(store.shopifyEmployeeId
          ? {shopifyEmployeeId: store.shopifyEmployeeId}
          : {}),
        ...(store.shopId ? {shopId: store.shopId} : {}),
      },
      {flush: true},
    );
  }
};

let cachedEmitPageView: undefined | ((detail: Partial<Store>) => void);

export const unloadPageView = () => {
  cachedEmitPageView = undefined;
};

export const initPageViewTracking = (
  track: Track,
  store: Store,
  updatePageView?: UpdatePageView,
) => {
  cachedUrlStr = '';
  if (updatePageView) {
    // Soft PageLoad
    cachedEmitPageView = (detail: Partial<Store>) => {
      // only do this if the page has not been unloaded
      updatePageView(detail);
      handlePageView({
        track,
        store,
        softNavigation: true,
      });
    };
  }

  // Inital (hard) PageLoad
  handlePageView({track, store});
};

export const emitPageView = (detail: Partial<Store> = {}) => {
  // proactively deduplicate pageView requests
  if (cachedUrlStr === location.href) {
    return;
  }

  // immediately update pageView data
  if (cachedEmitPageView) cachedEmitPageView(detail);

  // assume downstream consumers handle pageView resets async
  document.dispatchEvent(
    new CustomEvent(MonorailEventSchemaId.PageView, {
      detail,
    }),
  );
};
