import {HOSTNAME_LOCALE_MAPPINGS} from '../client/constants';

export const localeFromUrl = (url: URL): string | null => {
  const hostname = url.hostname;

  const hostToLocale =
    HOSTNAME_LOCALE_MAPPINGS[hostname] || HOSTNAME_LOCALE_MAPPINGS.default;

  if (typeof hostToLocale === 'string') {
    return hostToLocale;
  }

  const [, pathToLocale = ''] = url.pathname.toLowerCase().split('/');

  return hostToLocale[pathToLocale] || hostToLocale.default || 'en';
};
