import type {Metadata} from '@shopify/monorail/lib/metadata/metadata';
import type {
  WebsiteScrollEvent_1_0,
  WebsiteComponentViewabilityEvent_1_5,
} from '@shopify/monorail/lib/schemas';
import type {HttpProducerOptions} from '@shopify/monorail/lib/ts/producers/http-producer-options';

export interface MonorailEventSchema<T> {
  schemaId: T extends {schemaId: infer U} ? U : never;
  payload: T extends {payload: infer U} ? U : never;
  metadata?: Metadata;
  options?: HttpProducerOptions;
}

// Export as generic monorail event if referenced in multiple places
// eslint-disable-next-line @typescript-eslint/naming-convention
export type WebsiteScrollEvent_1 = WebsiteScrollEvent_1_0;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type WebsiteComponentViewabilityEvent_1 =
  WebsiteComponentViewabilityEvent_1_5;

export enum MonorailEventSchemaId {
  Click = 'website_click_event/1.5',
  ComponentViewability = 'website_component_viewability_event/1.5',
  ConversionSignalPageView = 'conversion_signal_pageview/2.1',
  Error = 'website_error_event/1.1',
  Form = 'website_form_event/2.0',
  MarketingId = 'website_marketing_id/1.0',
  MarketingPageEvent = 'marketing_page_event/2.0',
  PageView = 'website_client_page_view/2.13',
  ServerPageView = 'website_server_page_view/2.9',
  ServerRequest = 'website_server_request/1.0',
  Scroll = 'website_scroll_event/1.0',
  UserSession = 'website_user_session/2.2',
  UserSessionMarketing = 'website_user_session_marketing/1.1',
  VisibilityChange = 'website_visibility_change_event/1.0',
  WebVitals = 'website_web_vitals_event/2.0',
  PrivacySignal = 'website_privacy_signal/1.0',
  TrafficQuality = 'website_traffic_quality/1.0',
}
