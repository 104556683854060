import {v4} from 'uuid';

import {ApprovedCookie, SameSite} from '../enums';
import {localeFromUrl} from '../utils/localeFromUrl';
import type {ConfigMetadata, DataLayer, Site} from '../types';

import {initDidomi} from './components/didomiLoader';
import {DUX_INITIALIZED_ATTRIBUTE} from './constants';
import {
  initClickTracking,
  initComponentViewabilityTracking,
  initErrorTracking,
  initFormTracking,
  initScrollTracking,
  initTrafficQualityTracking,
  initVisibilityTracking,
  initWebVitalsTracking,
} from './loggers';
import {DuxTracker, GtmTracker} from './tracker';
import type {
  DuxConfig,
  Store,
  Track,
  UpdateStoreHook,
  UpdateTrackersHook,
} from './types';
import {getCookie, setCookie} from './utils/cookie';
import {removeAllListeners} from './utils/listeners';
import {getMicroSessionId} from './utils/trekkie';
import {getComplianceZone} from './utils/complianceZone';
import extractRootDomain from './utils/extractRootDomain';
import {initPageViewTracking, unloadPageView} from './loggers/pageView';

export {
  observeComponentViewability,
  unobserveComponentViewability,
} from './loggers';
export {emitPageView} from './loggers/pageView';

export const duxStore: Partial<Store> = {
  debug: true,
  emitTrekkiePageViewEvent: true,
  enableActiveConsent: false,
  isReady: false,
};

export const init = async (
  options: DuxConfig,
  updateStoreHook?: UpdateStoreHook,
  updateTrackersHook?: UpdateTrackersHook,
  globStore?: Partial<Store>,
  initTrackers?: Track,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (document.body.hasAttribute(DUX_INITIALIZED_ATTRIBUTE)) {
    // eslint-disable-next-line no-console
    console.warn(
      `[dux] warning: only initialize dux once. React <StrictMode> re-renders in dev; so you can ignore this if in development mode.`,
      options,
    );
    return;
  }

  // clean up any listeners that may have been added for a previously loaded page
  removeAllListeners();
  document.body.setAttribute(DUX_INITIALIZED_ATTRIBUTE, '1');

  // initStore config + globStore
  const store: Store = await initStore(
    options,
    updateStoreHook,
    globStore || duxStore,
  );

  const trackers = initTrackers || {
    dux: new DuxTracker(store).track,
    gtm: options?.enableGtm ? new GtmTracker(store).track : undefined,
  };

  if (updateTrackersHook && trackers) {
    updateTrackersHook(trackers);
  }

  /**
   * Merges partialStore into the existing store
   * @param partialStore attributes to update in the store
   */
  const updateStore = (partialStore: Partial<Store> = {}) => {
    if (updateStoreHook) {
      // the hook ensures the vanilla store is update
      updateStoreHook(partialStore, store);
    } else {
      Object.assign(store, partialStore);
    }
  };

  /**
   * Handles merging update attributes and rebuilding the current pageView data
   * @param partialStore attributes to change
   */
  const updatePageView = (partialStore: Partial<Store> = {}) => {
    const url = window.location.href;
    const pageData = getPageData(partialStore, url);

    const pageViewData = {
      ...partialStore,
      ...getUserSession(),
      ...pageData,
    };
    updateStore(pageViewData);
  };

  const {disableLogger} = options;
  if (!disableLogger?.page)
    initPageViewTracking(trackers, store, updatePageView);
  if (!disableLogger?.click)
    initClickTracking(trackers, store, [document.body]);
  if (!disableLogger?.componentViewability)
    initComponentViewabilityTracking(trackers, store);
  if (!disableLogger?.error) initErrorTracking(trackers, store);
  if (!disableLogger?.visibility) initVisibilityTracking(trackers, store);
  if (!disableLogger?.webVitals) initWebVitalsTracking(trackers, store);
  if (!disableLogger?.scroll) initScrollTracking(trackers, store);
  if (!disableLogger?.form) initFormTracking(trackers, store);
  if (!disableLogger?.trafficQuality)
    initTrafficQualityTracking(trackers, store);

  const {didomi} = options;
  if (didomi) {
    const {isEnabled: isDidomiEnabled} = didomi;
    if (isDidomiEnabled) {
      initDidomi({
        track: trackers,
        store,
        ...didomi,
        updateStore,
      });
    }
  }
};

/**
 * Get/Set/Update session tokens
 * @param url
 * @returns
 */
const getUserSession = () => {
  // initialize user and session cookies, if they are not already present
  const domain = extractRootDomain(new URL(window.location.href));
  const persistedMultiTrackToken = getCookie(ApprovedCookie.MultiTrackToken);
  const multiTrackToken = persistedMultiTrackToken || v4();
  if (multiTrackToken !== persistedMultiTrackToken) {
    setCookie(ApprovedCookie.MultiTrackToken, multiTrackToken, {
      maxage: 365 * 24 * 60 * 60 * 1000,
      path: '/',
      secure: true,
      samesite: SameSite.Lax,
      domain,
    });
  }

  const persistedSessionToken = getCookie(ApprovedCookie.SessionToken);
  const sessionToken = persistedSessionToken || v4();
  if (sessionToken !== persistedSessionToken) {
    setCookie(ApprovedCookie.SessionToken, sessionToken, {
      maxage: 30 * 60 * 1000,
      path: '/',
      secure: true,
      samesite: SameSite.Lax,
      domain,
    });
  }

  return {
    multiTrackToken,
    sessionToken,
    isNewUser: multiTrackToken !== persistedMultiTrackToken,
  };
};

/**
 * Get/Set/Update page data
 */
const getPageData = (partialStore: Partial<Store>, url: string) => {
  const pageViewToken = partialStore.pageViewToken || v4();
  const {
    // data that can change across page views
    metadata,
    canonicalUrl,
  } = partialStore;

  const currentURL = new URL(url);
  const pathPrefix =
    metadata?.site?.pathPrefix || localeFromUrl(currentURL) || '';
  const experimentVariationId = metadata?.page?.experimentVariationId || '';

  const page: DataLayer = {
    ...(metadata?.page as DataLayer),
    affiliate:
      metadata?.page?.affiliate ||
      currentURL.searchParams.get('partner') ||
      getCookie(ApprovedCookie.Affiliate) ||
      '',
    experimentVariationId,
  };

  const site: Site = {
    ...(metadata?.site as Site),
    pathPrefix,
  };

  return {
    pageViewToken,
    url,
    pageLanguageCode: pathPrefix?.substring(0, pathPrefix.indexOf('-')) || 'en',
    lastShopDomain: getCookie(ApprovedCookie.LastShop),
    canonicalUrl:
      canonicalUrl ||
      document.querySelector('link[rel="canonical"]')?.getAttribute('href') ||
      url.split(/[?#]/)[0],
    experimentVariationId,
    metadata: {
      ...metadata,
      title: metadata?.title || document.title,
      language: metadata?.page?.language || navigator.language || '',
      page,
      site,
      regions: [],
    } as ConfigMetadata,
  };
};

/**
 * guarantees creation of Store
 * @param partialStore
 * @param globStore
 * @returns
 */
const initStore = async (
  partialStore: DuxConfig,
  updateStoreHook?: UpdateStoreHook,
  globStore: Partial<Store> = duxStore,
) => {
  const {countryCode, regionCode, emitTrekkiePageViewEvent} = partialStore;
  const complianceZone = getComplianceZone(countryCode, regionCode);

  const url = window.location.href;

  // if we are not emitting a Trekkie page view event it is because Trekkie is
  // already loaded on the page, so we read in microSessionId and appName
  // from Trekkie configuration
  const pageViewToken = emitTrekkiePageViewEvent
    ? v4()
    : await getMicroSessionId();

  const pageData = getPageData(
    {
      pageViewToken,
      ...partialStore,
    },
    url,
  );

  // we mutate globStore and return it so re-initializations share previous state;
  const store: Store = Object.assign(globStore, {
    ...partialStore,
    ...getUserSession(),
    ...pageData,
    complianceZone,
    isReady: true,
  });

  if (updateStoreHook) {
    // syncs to zustand store
    updateStoreHook(store);
  }

  return store;
};

export const unload = () => {
  if (document.body.hasAttribute(DUX_INITIALIZED_ATTRIBUTE)) {
    document.body.removeAttribute(DUX_INITIALIZED_ATTRIBUTE);
    removeAllListeners();
    unloadPageView();
  }

  if (window.didomiOnReady) {
    window.didomiOnReady = [];
    window.didomiEventListeners = [];
  }
};
