import {SameSite} from '../../enums';

export interface CookieOptions {
  maxage?: number;
  path?: string;
  domain?: string;
  expires?: Date;
  secure?: boolean;
  samesite?: SameSite;
}

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') return;

  const regexp = new RegExp(`(?:^|; )${name}=([^;]*)(?:$|; )`);
  const match = document?.cookie.match(regexp);
  return match ? decodeURIComponent(match[1]) : '';
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions,
) => {
  if (typeof document === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn(
      'setCookie should only be called client-side',
      new Error('setCookie called outside of browser context.'),
    );
    return;
  }

  let str = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  if (options.maxage) {
    if (options.maxage < 0) {
      options.expires = new Date(0);
    } else {
      options.expires = new Date(new Date().getTime() + options.maxage);
    }
  }

  if (options.path) {
    str += `; path=${options.path}`;
  }

  if (options.domain) {
    str += `; domain=${options.domain}`;
  }

  if (options.expires) {
    str += `; expires=${options.expires.toUTCString()}`;
  }

  if (options.samesite) {
    str += `; SameSite=${options.samesite}`;
  } else {
    str += `; SameSite=${SameSite.Lax}`;
  }
  // webkit + playwright fails silently on secure cookies
  // https://github.com/microsoft/playwright/issues/5215
  if (options.secure && !(window as any).E2E) {
    str += '; secure';
  }
  document.cookie = str;
};
