import {v4} from 'uuid';

import type {
  Track,
  Store,
  ViewabilityObservation,
  ViewabilityOptions,
} from '../types';
import {GtmEventType} from '../types';
import {getComponentTreeInfo} from '../utils/componentTree';
import type {WebsiteComponentViewabilityEvent_1} from '../../schema-types';
import {MonorailEventSchemaId} from '../../schema-types';
import {camelCaseToSnakeCaseAllProps} from '../../utils/camelCaseToSnakeCase';
import {
  DEFAULT_COMPONENT_VIEWABILITY_DURATION,
  DEFAULT_COMPONENT_VIEWABILITY_THRESHOLD,
} from '../constants';
import type {ViewabilityHandler, ViewabilityHandlerProps} from '../../types';

let guid = 0;
const viewabilityObservers: Record<number, IntersectionObserver> = {};
const viewabilityObservations: Record<string, ViewabilityObservation> = {};

const getViewabilityObservationByDom = (
  dom: HTMLElement,
): ViewabilityObservation | undefined => {
  const observationId = Object.keys(viewabilityObservations).find((id) => {
    const {dom: domRef} = viewabilityObservations[id] || {};
    return domRef && domRef === dom;
  });
  return observationId ? viewabilityObservations[observationId] : undefined;
};

const canUseIntersectionObservers = () => {
  return (
    typeof window !== 'undefined' &&
    window.IntersectionObserver &&
    'intersectionRatio' in window.IntersectionObserverEntry?.prototype
  );
};

export const unobserveComponentViewability = (
  observation: ViewabilityObservation | undefined,
) => {
  if (!observation) {
    return;
  }

  if (observation.timer) {
    clearTimeout(observation.timer);
  }

  const {dom, id, observer, threshold} = observation;

  observer.unobserve(dom);

  // Clean up cached handlers on dom
  delete viewabilityObservations[id];

  // Clean up observer if it's no longer observing any components
  if (
    Object.values(viewabilityObservations).every(
      (otherObservation) => otherObservation.observer !== observer,
    )
  ) {
    delete viewabilityObservers[threshold];
  }
};

const getViewabilityObserver = ({
  track,
  store,
  threshold,
}: {
  track: Track;
  store: Store;
  threshold: number;
}): IntersectionObserver => {
  if (!viewabilityObservers[threshold]) {
    viewabilityObservers[threshold] = new IntersectionObserver(
      handleViewabilityWrapper(track, store),
      {
        root: null,
        threshold,
      },
    );
  }

  return viewabilityObservers[threshold];
};

export const observeComponentViewability = ({
  dom,
  handler,
  track,
  store,
  options,
  clientMessageId = v4(),
}: {
  dom: HTMLElement | null;
  handler?: ViewabilityHandler;
  track: Track;
  store: Store;
  options?: ViewabilityOptions;
  clientMessageId?: string;
}): ViewabilityObservation | undefined => {
  if (!dom || !canUseIntersectionObservers()) {
    return;
  }

  guid += 1;
  const id = guid.toString();
  const threshold =
    options?.threshold || DEFAULT_COMPONENT_VIEWABILITY_THRESHOLD;
  const duration = options?.duration || DEFAULT_COMPONENT_VIEWABILITY_DURATION;
  const observer = getViewabilityObserver({
    track,
    store,
    threshold,
  });
  const observation: ViewabilityObservation = {
    id,
    dom,
    handler,
    observer,
    threshold,
    duration,
    clientMessageId,
    extraMetadata: options?.extraMetadata || {},
  };
  viewabilityObservations[id] = observation;

  observer.observe(dom);

  return observation;
};

const fireEvents = ({
  observation,
  store,
  track,
}: {
  observation: ViewabilityObservation;
  store: Store;
  track: Track;
}): void => {
  const {dom, threshold, duration} = observation;
  const {componentTree, extraMetadata, elementName, sectionName, sectionIndex} =
    getComponentTreeInfo(dom, false);
  const combinedExtraMetadata = {
    ...extraMetadata,
    ...observation.extraMetadata,
  };

  const event: WebsiteComponentViewabilityEvent_1 = {
    schemaId: MonorailEventSchemaId.ComponentViewability,
    payload: {
      pageViewToken: store.pageViewToken || '',
      componentTree,
      targetName: elementName,
      parentName: sectionName,
      parentIndex: sectionIndex,
      componentWidth: dom.clientWidth,
      componentHeight: dom.clientHeight,
      verticalPosition: Math.round(
        window.scrollY + dom.getBoundingClientRect().top,
      ),
      horizontalPosition: Math.round(
        window.scrollX + dom.getBoundingClientRect().left,
      ),
      duration,
      threshold,
      extraMetadata: JSON.stringify(combinedExtraMetadata),
    },
  };

  track.dux(event, {clientMessageId: observation.clientMessageId});

  if (track.gtm) {
    track.gtm({
      event: GtmEventType.ComponentViewability,
      eventType: elementName,
      eventLocation: componentTree,
      extraMetadata: JSON.stringify(
        camelCaseToSnakeCaseAllProps(combinedExtraMetadata),
      ),
    });
  }

  unobserveComponentViewability(observation);
};

const handleViewabilityWrapper =
  (track: Track, store: Store): IntersectionObserverCallback =>
  (entries) => {
    entries.forEach((entry) => {
      // callback the optional handler in component
      const target: HTMLElement = entry?.target as HTMLElement;
      const observation = getViewabilityObservationByDom(target);

      if (!observation) {
        return;
      }

      const sharedProps: Omit<ViewabilityHandlerProps, 'isIntersecting'> = {
        store,
        track,
        dom: target,
        clientMessageId: observation.clientMessageId,
        parentSchemaId: MonorailEventSchemaId.ComponentViewability,
      };

      if (entry.isIntersecting) {
        if (observation.duration > 0) {
          observation.timer = setTimeout(() => {
            fireEvents({observation, store, track});
            observation.handler?.({
              ...sharedProps,
              isIntersecting: true,
            });
          }, observation.duration);
        } else {
          observation.handler?.({
            ...sharedProps,
            isIntersecting: true,
          });
          fireEvents({observation, store, track});
        }
      } else {
        observation.handler?.({
          ...sharedProps,
          isIntersecting: false,
        });

        if (observation.timer) {
          clearTimeout(observation.timer);
        }
      }
    });
  };

export const initComponentViewabilityTracking = (
  track: Track,
  store: Store,
) => {
  if (!canUseIntersectionObservers()) {
    return;
  }

  document.querySelectorAll('[data-viewable-component]').forEach((component) =>
    observeComponentViewability({
      track,
      store,
      dom: component as HTMLElement,
    }),
  );

  // The IntersectionObserver might be created async, it may not have existed when we tried to add observable targets
  // so observe them here if they already exist
  Object.values(viewabilityObservations).forEach(({observer, dom}) => {
    observer.observe(dom);
  });
};
